import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import { PeriodicTerm } from '@/model/enums/periodic-term';
import PeriodicOption from '@/model/periodic-option';

const { t }: Composer = i18n.global;

export const periodicOptions = [
  {
    title: 'dashboard.requirement-addition.periodic.one-week',
    value: new PeriodicOption(1, PeriodicTerm.WEEK),
  },
  {
    title: 'dashboard.requirement-addition.periodic.two-week',
    value: new PeriodicOption(2, PeriodicTerm.WEEK),
  },
  {
    title: 'dashboard.requirement-addition.periodic.one-month',
    value: new PeriodicOption(1, PeriodicTerm.MONTH),
  },
  {
    title: 'dashboard.requirement-addition.periodic.half-year',
    value: new PeriodicOption(6, PeriodicTerm.MONTH),
  },
  {
    title: 'dashboard.requirement-addition.periodic.one-year',
    value: new PeriodicOption(1, PeriodicTerm.YEAR),
  },
  {
    title: 'dashboard.requirement-addition.periodic.three-years',
    value: new PeriodicOption(3, PeriodicTerm.YEAR),
  },
  {
    title: 'dashboard.requirement-addition.periodic.six-years',
    value: new PeriodicOption(6, PeriodicTerm.YEAR),
  },
  {
    title: 'dashboard.requirement-addition.periodic.custom',
    value: new PeriodicOption(null, null),
  },
];
