import moment from 'moment';
import {
  SHORT_DEFAULT_DATE_FORMAT,
  LONG_DEFAULT_DATE_FORMAT,
  PICKER_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  NUMBER_DATE_FORMAT,
} from '@/constants/date-format';
import User from '@/model/user';
import Person from '@/model/person';
import UserDetails from '@/model/user-details';
import { periodicTerms } from '@/constants/periodic-terms';
import { periodicOptions } from '@/constants/periodic-options';
import Requirement from '@/model/requirement';
import AdminRequirement from '@/model/admin-requirement';
import RequirementDocument from '@/model/requirement-document';
import PeriodicOption from '@/model/periodic-option';
import store from '@/store';

export const formatterMixin = {
  computed: {
    currentDate(): string {
      return moment(new Date()).format(PICKER_DATE_FORMAT);
    },
  },
  methods: {
    formatDate(date: Date): string {
      const language = store.getters['languageStorage/getLanguage'];
      moment.locale(language);
      return date ? moment(date).format(LONG_DEFAULT_DATE_FORMAT).toLowerCase() : '';
    },
    formatDateShort(date: Date) {
      const language = store.getters['languageStorage/getLanguage'];
      moment.locale(language);
      return date ? moment(date).format(SHORT_DEFAULT_DATE_FORMAT).toLowerCase() : '';
    },
    formatDateTime(date: string) {
      const language = store.getters['languageStorage/getLanguage'];
      moment.locale(language);
      return date
        ? moment(new Date(`${date} UTC`))
            .format(DEFAULT_DATE_TIME_FORMAT)
            .toLowerCase()
        : '';
    },
    formatNumberDate(date: Date) {
      const language = store.getters['languageStorage/getLanguage'];
      moment.locale(language);
      return moment(date).format(NUMBER_DATE_FORMAT);
    },
    createMonogram(name: string): string {
      const nameParts = name.split(' ');
      let monogram = '';
      nameParts.forEach((part) => {
        if (monogram.length < 2) {
          monogram += part.substring(0, 1).toUpperCase();
        }
      });
      return monogram;
    },
    fullName(user: User | UserDetails | Person): string {
      return user?.lastName + ' ' + user?.firstName;
    },
    convertToMb(bytes: number): number {
      const converted = bytes * 10 ** -6;
      return Math.round(converted * 100) / 100;
    },
    shortenFileName(filename: string, maxlength: number): string {
      return filename.length > maxlength
        ? `${filename.substring(0, filename.lastIndexOf('.')).substring(0, maxlength - 5)}...${filename.substring(
            filename.lastIndexOf('.'),
          )}`
        : filename;
    },
    sortSubrequirements(
      subrequirements: Requirement[] | AdminRequirement[] | RequirementDocument[],
    ): Requirement[] | AdminRequirement[] | RequirementDocument[] {
      return [...subrequirements].sort((a, b) => (a.technical === b.technical ? 0 : a.technical ? -1 : 1));
    },
    formatPeriod(period: PeriodicOption): string {
      return (
        periodicOptions
          .find(
            (option) => option.value.frequencyNumber === period.frequencyNumber && option.value.term === period.term,
          )
          ?.title?.toString() ??
        `${period.frequencyNumber} ${periodicTerms.find((termOption) => termOption.value === period.term)?.title}`
      );
    },
  },
};
