export enum Route {
  DASHBOARD = 'Dashboard',
  DOCUMENTS = 'Documents',
  BASIC_INFORMATION = 'BasicInformation',
  BASIC_INFORMATION_PLACES = 'BasicInformationPlaces',
  BASIC_INFORMATION_PEOPLE = 'BasicInformationPeople',
  BASIC_INFORMATION_TOOLS = 'BasicInformationTools',
  ORGANIZATIONAL_MAP = 'OrganizationalMap',
  USER_DETAILS = 'UserDetails',
  ADMIN = 'Admin',
  ADMIN_USERS = 'AdminUsers',
  ADMIN_TYPES = 'AdminTypes',
  ADMIN_REQUIREMENTS = 'AdminRequirements',
  ADMIN_BASIC_INFORMATION_OPTIONS = 'BasicInformationOptions',
  LOGIN = 'Login',
}

export enum RoutePath {
  DASHBOARD = '/home',
  DOCUMENTS = '/documents',
  BASIC_INFORMATION = '/basic-info',
  BASIC_INFORMATION_PLACES = '/locations',
  BASIC_INFORMATION_PEOPLE = '/people',
  BASIC_INFORMATION_TOOLS = '/work-equipment',
  ORGANIZATIONAL_MAP = '/organizational-map',
  USER_DETAILS = '/user-details',
  ADMIN = '/admin-settings',
  ADMIN_USERS = '/admin-settings/users',
  ADMIN_TYPES = '/admin-settings/types',
  ADMIN_REQUIREMENTS = '/admin-settings/requirements',
  ADMIN_BASIC_INFORMATION_OPTIONS = '/admin-settings/options',
  LOGIN = '/login',
}
