import adminRequirementStorage from '@/store/modules/admin/admin-requirement-storage';
import adminTypeStorage from '@/store/modules/admin/admin-type-storage';
import adminUserStorage from '@/store/modules/admin/admin-user-storage';
import businessTypeStorage from '@/store/modules/business-type-storage';
import businessStorage from '@/store/modules/business-storage';
import changelogStorage from '@/store/modules/changelog-storage';
import commentStorage from '@/store/modules/comment-storage';
import configurationStorage from '@/store/modules/configurations-storage';
import currentUserStorage from '@/store/modules/current-user-storage';
import delivererStorage from '@/store/modules/deliverer-storage';
import documentContainerStorage from '@/store/modules/document-container-storage';
import documentStorage from '@/store/modules/document-storage';
import educationStorage from '@/store/modules/education-storage';
import filterStorage from '@/store/modules/filter-storage';
import languageStorage from '@/store/modules/language-storage';
import medicalExaminationStorage from '@/store/modules/medical-examination-storage';
import messageStorage from '@/store/modules/message-storage';
import notificationStorage from '@/store/modules/notification-storage';
import operationalUnitStorage from '@/store/modules/operational-unit-storage';
import personCategoryStorage from '@/store/modules/person-category-storage';
import personRoleStorage from '@/store/modules/person-role-storage';
import personStorage from '@/store/modules/person-storage';
import protectorStorage from './modules/protector-storage';
import qualificationStorage from '@/store/modules/qualification-storage';
import requirementStorage from '@/store/modules/requirement-storage';
import requirementTaskStorage from '@/store/modules/requirement-task-storage';
import systemStorage from '@/store/modules/system-storage';
import toolCategoryStorage from '@/store/modules/tool-category-storage';
import toolStorage from '@/store/modules/tool-storage';
import userStorage from '@/store/modules/user-storage';
import workFieldStorage from '@/store/modules/work-field-storage';
import createPersist from 'vuex-persist';
import { createStore } from 'vuex';

const vuexLocal = new createPersist({
  storage: window.localStorage,
  modules: ['currentUserStorage', 'filterStorage', 'operationalUnitStorage', 'systemStorage', 'languageStorage'],
});

const store = createStore({
  modules: {
    adminRequirementStorage,
    adminTypeStorage,
    adminUserStorage,
    businessTypeStorage,
    businessStorage,
    changelogStorage,
    commentStorage,
    configurationStorage,
    currentUserStorage,
    delivererStorage,
    documentContainerStorage,
    documentStorage,
    educationStorage,
    filterStorage,
    languageStorage,
    medicalExaminationStorage,
    messageStorage,
    notificationStorage,
    operationalUnitStorage,
    personCategoryStorage,
    personRoleStorage,
    personStorage,
    protectorStorage,
    qualificationStorage,
    requirementStorage,
    requirementTaskStorage,
    systemStorage,
    toolCategoryStorage,
    toolStorage,
    userStorage,
    workFieldStorage,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
