import { i18n } from '@/i18n';
import { Language } from '@/model/enums/language';

export default {
  namespaced: true,
  state: {
    language: Language.HU,
  },
  mutations: {
    setLanguage(state: { language: string }, payload: Language) {
      state.language = payload;
      i18n.global.locale.value = payload;
    },
  },
  actions: {
    changeLanguage({ commit, dispatch, rootGetters }: any, language: Language) {
      if (rootGetters['currentUserStorage/isLoggedIn']) {
        dispatch('currentUserStorage/changeLanguage', language, { root: true }).then(() =>
          commit('setLanguage', language),
        );
      } else {
        commit('setLanguage', language);
      }
    },
  },
  getters: {
    getLanguage(state: { language: string }): string {
      return state.language;
    },
  },
};
