import axios from 'axios';
import router from '@/router/index';
import { i18n, Languages } from '@/i18n';
import { Composer } from 'vue-i18n';
import LoginRequest from '@/model/request/login-request';
import User from '@/model/user';
import ChangePasswordRequest from '@/model/request/change-password-request';
import ChangeEmailRequest from '@/model/request/change-email-request';
import ChangeNameRequest from '@/model/request/change-name-request';
import { Role } from '@/model/enums/role';
import { Category } from '@/model/enums/category';
import { jwtDecode } from 'jwt-decode';
import JwtToken from '@/model/jwt-token';
import File from '@/model/file';
import UserDetails from '@/model/user-details';
import moment from 'moment';
import { DEFAULT_DATE_TIME_FORMAT } from '@/constants/date-format';
import { Route } from '@/model/enums/route';
import { Language } from '@/model/enums/language';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    currentUser: User,
  },
  mutations: {
    setCurrentUser(state: { currentUser: User }, payload: User) {
      state.currentUser = payload;
    },
    setAccessExpiry(state: { currentUser: User }, payload: number) {
      state.currentUser.accessTokenExpiry = payload * 1000;
    },
    setRefreshExpiry(state: { currentUser: User }, payload: number) {
      state.currentUser.refreshExpiry = payload * 1000;
    },
    setWorkFields(state: { currentUser: User }, payload: Category[]) {
      state.currentUser.workfields = payload;
    },
    setEmail(state: { currentUser: User }, payload: string) {
      state.currentUser.email = payload;
    },
    setName(state: { currentUser: User }, payload: ChangeNameRequest) {
      state.currentUser.firstName = payload.firstName;
      state.currentUser.lastName = payload.lastName;
    },
    setNotificationIsAllowed(state: { currentUser: User }, payload: boolean) {
      state.currentUser.allowEmails = payload;
    },
    setProfilePicture(state: { currentUser: User }, payload: File) {
      state.currentUser.profilePicture = payload;
    },
    setUserDetails(state: { currentUser: User }, payload: UserDetails) {
      if (state.currentUser.id === payload.id) {
        state.currentUser.email = payload.email;
        state.currentUser.firstName = payload.firstName;
        state.currentUser.lastName = payload.lastName;
        state.currentUser.roles = payload.roles;
        state.currentUser.workfields = payload.workfields;
        state.currentUser.external = payload.external;
        state.currentUser.business = payload.business;
        state.currentUser.deleted = payload.deleted;
      }
    },
    setLanguage(state: { currentUser: User }, payload: Language) {
      state.currentUser.language = payload;
    },
  },
  actions: {
    login({ commit, dispatch, getters, rootGetters }: any, user: LoginRequest) {
      return axios
        .post('/login', user)
        .then(({ data }) => {
          if (data.accessToken === undefined) {
            return;
          }

          commit('setCurrentUser', data);
          commit('setAccessExpiry', jwtDecode<JwtToken>(data.accessToken).exp);
          commit('setRefreshExpiry', jwtDecode<JwtToken>(data.refreshToken).exp);
          commit('setDocumentPanels', [0, 1, 2, 3], { root: true });

          const localLanguage: Language = rootGetters['languageStorage/getLanguage'];

          if (data?.language?.toLowerCase() !== localLanguage) {
            dispatch('changeLanguage', localLanguage);
          }

          if (getters.isGuest) {
            if (Date.now() >= new Date(`${getters.getCurrentUser?.guestExpiryDate} UTC`).valueOf()) {
              dispatch('logout');
              dispatch(
                'showWarningNotification',
                {
                  message: t('notification.guest.log-out'),
                },
                { root: true },
              );
            } else {
              router.push({ name: Route.DOCUMENTS });
              dispatch(
                'showInfoNotification',
                {
                  message: t('notification.guest.available-until', {
                    time: moment(new Date(`${getters.getCurrentUser?.guestExpiryDate} UTC`))
                      .locale(rootGetters['languageStorage/getLanguage'])
                      .format(DEFAULT_DATE_TIME_FORMAT),
                  }),
                },
                { root: true },
              );
            }
          } else {
            if (!getters.isEmployee && (getters.isHr || getters.isProcurement)) {
              router.push({ name: Route.ORGANIZATIONAL_MAP });
            } else {
              commit('setDashboardPanels', [0, 1, 2, 3], { root: true });
              router.push({ name: Route.DASHBOARD });
            }
            if (getters.isFirstLogin) {
              dispatch(
                'showInfoNotification',
                {
                  message: t('account.password-change'),
                },
                { root: true },
              );
            }
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    logout({ commit }: any) {
      const auth = 'Authorization';
      axios.defaults.headers.common[auth] = '';
      commit('setDashboardPanels', [], { root: true });
      commit('setDocumentPanels', [], { root: true });
      commit('setDashboardSubPanels', [[], [], [], []], { root: true });
      commit('setDocumentSubPanels', [[], [], [], []], { root: true });
      commit('filterStorage/clearRequirementFilterOptions', null, { root: true });
      commit('filterStorage/setRequirementSearchText', '', { root: true });
      commit('filterStorage/setAdminUsersSearchText', '', { root: true });
      commit('filterStorage/setAdminTypesSearchText', '', { root: true });
      commit('filterStorage/setAdminRequirementsSearchText', '', { root: true });
      commit('filterStorage/setAdminBasicInformationOptionsSearchText', '', { root: true });
      commit('filterStorage/setDocumentSearchText', '', { root: true });
      commit('setCurrentUser', {});
    },
    changePassword({ dispatch }: any, changePasswordRequest: ChangePasswordRequest) {
      return axios
        .post('/user/change-password', changePasswordRequest)
        .then(({ data }) => {
          dispatch('showSuccessNotification', { message: t('notification.success.password-change') }, { root: true });
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    changeEmail({ dispatch, commit, getters }: any, changeEmailRequest: ChangeEmailRequest) {
      return axios
        .post('/user/change-email', changeEmailRequest)
        .then(({ data }) => {
          commit('setEmail', changeEmailRequest.newEmail);
          commit(
            'userStorage/setEmail',
            { userId: getters.getCurrentUser.id, newEmail: changeEmailRequest.newEmail },
            { root: true },
          );
          dispatch('showSuccessNotification', { message: t('notification.success.email-change') }, { root: true });
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    changeName({ dispatch, commit, getters }: any, changeNameRequest: ChangeNameRequest) {
      return axios
        .post('/user/change-name', changeNameRequest)
        .then(({ data }) => {
          commit('setName', changeNameRequest);
          commit(
            'userStorage/setName',
            { userId: getters.getCurrentUser.id, newName: changeNameRequest },
            { root: true },
          );
          dispatch('showSuccessNotification', { message: t('notification.success.name-change') }, { root: true });
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    refreshCredentials({ commit, getters }: any) {
      return axios
        .post('/authentication/refresh', { refreshToken: getters.getCurrentUser?.refreshToken })
        .then(({ data }) => {
          commit('setCurrentUser', data);
          commit('setAccessExpiry', jwtDecode<JwtToken>(data.accessToken).exp);
          commit('setRefreshExpiry', jwtDecode<JwtToken>(data.refreshToken).exp);
        });
    },
    turnNotifications({ commit, dispatch }: any) {
      return axios
        .post('/user/turn-notification')
        .then(({ data }) => {
          commit('setNotificationIsAllowed', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadProfilePicture({ commit, dispatch, getters }: any, formData: FormData) {
      return axios
        .post('/user/picture', formData)
        .then(({ data }) => {
          if (data) {
            commit('setProfilePicture', data);
            commit(
              'userStorage/setProfilePicture',
              { userId: getters.getCurrentUser.id, picture: data },
              { root: true },
            );
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deleteProfilePicture({ commit, dispatch }: any) {
      return axios
        .delete('/user/picture')
        .then(({ data }) => {
          if (data) {
            commit('setProfilePicture', null);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    updateCredentials({ commit, dispatch, rootGetters }: any) {
      return axios
        .get('/user/update')
        .then(({ data }) => {
          if (data) {
            commit('setUserDetails', data);

            const localLanguage: Language = rootGetters['languageStorage/getLanguage'];

            if (data?.language?.toLowerCase() !== localLanguage) {
              dispatch('changeLanguage', localLanguage);
            }

            if (data.deleted) {
              dispatch('logout');
              router.push({ name: Route.LOGIN });
            }
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    changeLanguage({ dispatch }: any, language: string) {
      return axios.patch('/user/language', { key: language?.toUpperCase() }).catch((error) => {
        dispatch('defaultErrorMessage', error, { root: true });
      });
    },
  },
  getters: {
    isFirstLogin(state: { currentUser: User }) {
      return state.currentUser?.firstlogin;
    },
    isLoggedIn(state: { currentUser: User }): boolean {
      return !!state?.currentUser?.refreshToken;
    },
    getCurrentUser(state: { currentUser: User }) {
      return state.currentUser;
    },
    getRoles(state: { currentUser: User }) {
      return state.currentUser?.roles ?? [];
    },
    isManager(state: { currentUser: User }) {
      return state.currentUser?.roles?.includes(Role.MANAGER);
    },
    isGuest(state: { currentUser: User }) {
      return state.currentUser?.roles?.includes(Role.GUEST);
    },
    isEmployee(state: { currentUser: User }) {
      return state.currentUser?.roles?.includes(Role.EMPLOYEE);
    },
    isAdmin(state: { currentUser: User }) {
      return state.currentUser?.roles?.includes(Role.ADMIN);
    },
    isHr(state: { currentUser: User }) {
      return state.currentUser?.roles?.includes(Role.HR);
    },
    isProcurement(state: { currentUser: User }) {
      return state.currentUser?.roles?.includes(Role.PROCUREMENT);
    },
  },
};
