import { createI18n } from 'vue-i18n';
import { Language } from './model/enums/language';

export const Languages = {
  hu: Language.HU,
  en: Language.EN,
};

const localStorage = JSON.parse(window.localStorage.getItem('vuex') ?? '{}');

function loadLocaleMessages(): any {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: any = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const i18n = createI18n({
  locale: localStorage?.languageStorage?.language || process.env.VUE_APP_I18N_LOCALE || Language.HU,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Language.HU,
  messages: loadLocaleMessages(),
  legacy: false,
  globalInjection: true,
});
